:root {
    --global-ok: #69d29a;
    --global-alert: #ffde5a;
    --global-danger: #ff5858;
    --header-background-color: rgb(168, 234, 255);
    --header-color: #1f1f1f;
    --header-nav-color: #4f4f4f;
    --header-nav-color-hover: #383838;
    --header-drawer-icon-color: #4f4f4f;
    --header-drawer-icon-color-hover: #383838;
    --header-shade-color: #a2a2a2;
    --header-lang-menu-bg-hover: #a6a6a6ff;
    --header-router-color-text: #222323;

    --body-content-bg: #f3f3f3;
    --body-content-bg-gray: #eaeaea;
    --body-nav-expand-text-color: #424242;
    --body-content-span-line-creative: #89ceff;
    --body-span-line-syapole: #00cdda;
    --body-span-line-coston: #ffd275;
    --body-span-line-southpark: #3cde80;
    --body-span-line-kitajuku: #ffae42;
    --body-span-line-hokuriku: #beb6ff;
    --body-span-line-chikai: #56c0fd;
    --body-span-line-pyutocor: #8ae1ca;
    --body-span-line-duka: #ff6fa4;
    --body-span-line-hokkai: #f13b3b;
    --body-content-span-text-color: #383838;

    --error-span-line: #606060;
    --error-link-hover-color: #1e1e1e;

    --footer-bg-color: #dadada;
    --footer-shade-color: #484848;
    --footer-text-color: #2f2f2f;
    --footer-svg-color: #030303;
    --footer-secondary-text-color: #d9d9d9;
    --footer-shade-secondary: #252525;
    --footer-transition-color: #c6c6c6;
}

:root .dark{
    --global-ok: #3e805b;
    --global-alert: rgba(180, 154, 54, 1);
    --global-danger: rgba(194, 55, 55, 1);
    --header-background-color: rgb(0, 7, 26);
    --header-color: #e5e5e5;
    --header-nav-color: #e5e5e5;
    --header-nav-color-hover: #e3e3e3;
    --header-drawer-icon-color: #e5e5e5;
    --header-drawer-icon-color-hover: #e3e3e3;
    --header-shade-color: rgba(0, 59, 80, 0.35);
    --header-lang-menu-bg-hover: #1e1e1e;
    --header-router-color-text: #f5f5f5;

    --body-content-bg: #252525;
    --body-content-bg-gray: #212121;
    --body-nav-expand-text-color: #e5e5e5;
    --body-content-span-line-creative: rgb(78, 118, 159);
    --body-content-span-text-color: #e3e3e3;
    --body-span-line-syapole: #005e67;
    --body-span-line-coston: #a98344;
    --body-span-line-southpark: #00652c;
    --body-span-line-kitajuku: #8d5800;
    --body-span-line-hokuriku: #716bc2;
    --body-span-line-chikai: #306e8c;
    --body-span-line-pyutocor: #4a8071;
    --body-span-line-duka: #b0516f;
    --body-span-line-hokkai: #a12121;

    --error-span-line: #8d8d8d;
    --error-link-hover-color: #e3e3e3;

    --footer-bg-color: #030303;
    --footer-shade-color: rgba(0, 59, 80, 0.35);
    --footer-text-color: #e3e2e2;
    --footer-svg-color: #4e4e4e;
    --footer-secondary-text-color: #e3e3e3;
    --footer-shade-secondary: #5e5e5e;
    --footer-transition-color: #3e3e3e;
    --body-picture-viewer-filter-parameter: grayscale(40%);

}

* {
    font-family:  "Noto Sans SC", sans-serif, Charter, Arial, "Noto Sans JP";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.router-link-active{
    color: var(--header-router-color-text);
    text-decoration: none;
}

body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}